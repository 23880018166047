/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { jwtDecode } from "jwt-decode";
import Layout from "../../components/layout";
import H1 from "../../components/htmlElements/h1";
import { AuthenticationState } from "../../context/AuthenticationContext";
import { getProjectName, validateToken } from "../../lib/functions";
import H2 from "../../components/htmlElements/h2";
import UserSubmissions from "../../components/userElements/userSubmissions";
import LoadingIcon from "../../components/loadingIcon";

export default function UserOverviewPage() {
  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const { isAuthenticated, token } = authenticationState;

  const [userName, setUserName] = useState("");
  const [userVerified, setUserVerified] = useState(false);

  const projectName = getProjectName();

  useEffect(() => {
    (async () => {
      if (isAuthenticated && token) {
        await validateToken(token);

        // Decode the token
        const decodeToken: any = jwtDecode(token);

        // Get user's first name from the token OR if empty their email address as alternative display name
        setUserName(
          decodeToken.firstName ? decodeToken.firstName : decodeToken.email
        );
        setUserVerified(true);

        // If user is not logged in, or token is invalid, redirect user to login page
      } else {
        setUserName("");

        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }

      return true;
    })();
  }, []);

  const { irisText, oasisText } = useStaticQuery(graphql`
    query {
      irisText: markdownRemark(
        frontmatter: { pageId: { eq: "IRIS-user-overview" } }
      ) {
        frontmatter {
          title
        }
        html
      }

      oasisText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-user-overview" } }
      ) {
        frontmatter {
          title
        }
        html
      }
    }
  `);

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          {userVerified ? (
            <>
              <H1
                innerContent={`Welcome back ${userName}`}
                additionalClasses="pb-5"
              />

              <div
                className="markdownText"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    projectName === "oasis" ? oasisText.html : irisText.html,
                }}
              />

              <H2 innerContent="My submissions" additionalClasses="pb-5" />

              <div className="pb-5">
                <UserSubmissions userVerified={userVerified} />
              </div>
            </>
          ) : (
            <div className="text-center">
              <LoadingIcon />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
